export const colors: readonly string[] = [
  "#00B2EE",
  "#009ACD",
  "#2C3E50",
  "#7F8C8D",
  "#8E44AD",
  "#9B59B6",
  "#95A5A6",
  "#436EEE",
  "#2980B9",
  "#3498DB",
  "#595959",
  "#B0E0E6",
  "#BCD2EE",
  "#BDC3C7",
  "#BF3EFF",
  "#C0392B",
  "#D35400",
  "#E67E22",
  "#E74C3C",
  "#EE5C42",
  "#F1C40F",
  "#FF69B4",
  "#FFB90F",
];
