// extracted by mini-css-extract-plugin
var _1 = "Ov5_gg8mqB";
var _2 = "jAWlBK1ydT";
var _3 = "iixeKSmGoA";
var _4 = "ZXLugIpoG5";
var _5 = "EnwzInHrn0";
var _6 = "_e2Sc9se_w";
var _7 = "n64G5u7MY3";
var _8 = "rEZgPALkTR";
export { _1 as "body", _2 as "bodyAlt", _3 as "main", _4 as "mainAlt", _5 as "nav", _6 as "navAlt", _7 as "sidebar", _8 as "topbar" }
